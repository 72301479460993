import getAppHostConfig from "./getAppHostConfig";
// TODO: do per-env builds with this env var set, or get it from atms-web-bff,
// so we don't have to infer it
const getEnvironmentNameFromHost = () => {
  const { BASE_HOST } = getAppHostConfig(
    window.location.hostname,
    window.location.port
  );
  return {
    "atms.a2z.com": "prod",
    "staging.atms.a2z.com": "staging",
    "beta2.atms.a2z.com": "beta",
    "load.atms.a2z.com": "load"
  }[BASE_HOST];
};
export default getEnvironmentNameFromHost() || "dev";
