import { useCallback, useEffect, useReducer } from "react";
import SessionReducer from "./SessionReducer";
import cookieName from "../../shared/session/sessionCookieName";
import * as Cookies from "js-cookie";
import { User } from "../types/commonTypes";
import GetUserInfo from "./GetUserInfoApiClient";
import { UNAUTHORIZED, AUTHENTICATED } from "../types/constants";

let userInfo: User = {
  userId: -1,
  userName: "",
  firstName: "",
  lastName: "",
  email: "",
  organizationId: -1,
  role: "",
  authenticationStatus: "",
  availableProfiles: [],
  csrfToken: "",
  systemInfoMessage: ""
};

const useSession = () => {
  console.log("ESSESION");
  const [session, setSession] = useReducer(SessionReducer, {
    jwtToken: "",
    user: userInfo,
    systemInfoMessage: ""
  });
  const jwtTokenInCookie = Cookies.get(cookieName);

  const getUserInfo = useCallback(async () => {
    await GetUserInfo().then(response => {
      userInfo = response;
      if (userInfo.authenticationStatus == AUTHENTICATED)
        setSession({
          type: "UpdateSession",
          jwtToken: jwtTokenInCookie,
          user: userInfo,
          systemInfoMessage: userInfo.systemInfoMessage
        });
      else if (userInfo.authenticationStatus == UNAUTHORIZED)
        setSession({
          type: "InvalidSession",
          jwtToken: "",
          user: userInfo,
          systemInfoMessage: userInfo.systemInfoMessage
        });
      else
        setSession({
          type: "BackEndServerError",
          jwtToken: "",
          user: userInfo,
          systemInfoMessage: userInfo.systemInfoMessage
        });
    });
  }, []);

  useEffect(() => {
    if (jwtTokenInCookie == "")
      setSession({
        type: "InvalidSession",
        jwtToken: "",
        user: userInfo,
        systemInfoMessage: userInfo.systemInfoMessage
      });
    else if (jwtTokenInCookie != session.jwtToken) getUserInfo();
  }, [jwtTokenInCookie]);

  return session;
};

export default useSession;
