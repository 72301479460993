import React, { ReactElement } from "react";
import { ProfileDropdown } from "@amzn/et-console-components";
import getAppHostConfig from "../../shared/session/getAppHostConfig";
import styled from "@emotion/styled";
import { User } from "../types/commonTypes";

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const SectionCss = styled.section({
  flexGrow: 0,
  flexShrink: 0,
  height: 40,
  display: "flex",
  background: "#232f3e",
  alignItems: "center",
  "& a, & b": {
    color: "#fff",
    padding: "0 10px",
    lineHeight: "40px",
    height: "100%",
    textDecoration: "none"
  }
});

const BannerDiv = styled.div({
  flexGrow: 1,
  padding: 0,
  margin: 0,
  textIndent: "-1000em",
  display: "block",
  backgroundPosition: "center center",
  backgroundSize: "30%",
  backgroundRepeat: "no-repeat",
  width: 140
});

export default ({ session }) => {
  let user = session.user as User;

  return (
    <SectionCss>
      <BannerDiv>ATMS Invoices</BannerDiv>
      <ProfileDropdown
        user={user}
        availableProfiles={user.availableProfiles}
        switchProfileUri={`//${WEB_HOST_AND_PORT}/web/assumeProfile?csrfToken=${user.csrfToken}&name={username}`}
        signOutUri={`//${WEB_HOST_AND_PORT}/web/logout/index?csrfToken=${user.csrfToken}`}
      />
    </SectionCss>
  );
};
