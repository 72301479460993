import React, { Component } from "react";
import { AppLayout } from "@amzn/awsui-components-react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { redirectToCognitoIfNecessary } from "./shared/session/AuthenticationForward";
import getAppHostConfig from "./shared/session/getAppHostConfig";
import { UNAUTHORIZED } from "./app/types/constants";
import useSession from "./app/Session/useSession";
import { SystemStatusFlash } from "@amzn/et-console-components";
import InvoiceSubmissionComponent from "./app/InvoiceSubmission/InvoiceSubmissionComponent";
import NavigationModal from "./app/components/NavigationModal";
import TopBanner from "./app/components/TopBanner";

const { WEB_HOST_AND_PORT } = getAppHostConfig();

const withAuth = Component => props => {
  const isRedirecting = redirectToCognitoIfNecessary(document.cookie);

  if (isRedirecting) {
    return null;
  }
  return <Component {...props} />;
};

const App = withAuth(() => {
  const session = useSession();

  // Need to throw error boundary for invalid payload
  if (session.user.authenticationStatus == UNAUTHORIZED) {
    window.location.replace(
      `//${WEB_HOST_AND_PORT}/web/login/auth?backUrl=${encodeURIComponent(
        window.location.toString()
      )}`
    );
    return null;
  } else {
    return (
      <div className="awsui">
        <div id="header">
          <SystemStatusFlash session={session} />
          <TopBanner session={session} />
        </div>
        <Router>
          <Switch>
            <Route path="/invoices/submit">
              <AppLayout
                content={<InvoiceSubmissionComponent session={session} />}
                navigation={<NavigationModal />}
                toolsHide={true}
              />
            </Route>
            <Route path="/invoices">
              <h2>ATMS Invoices</h2>
            </Route>
          </Switch>
        </Router>
      </div>
    );
  }
});

export default () => <App />;
