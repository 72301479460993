import sessionCookieName from "./sessionCookieName";
import getAppHostConfig from "./getAppHostConfig";
import memoize from "./memoize";

// this may be called multiple times (successive renders), but should only
// redirect once
export const redirectToCognitoIfNecessary = memoize(cookie => {
  const { WEB_HOST_AND_PORT } = getAppHostConfig();

  // If the session hasn't been set, forward to somewhere they can authenticate with Cognito since you cannot put
  // Cognito in an iframe since they set x-frame-options to deny.
  if (cookie.indexOf(`${sessionCookieName}=`) === -1) {
    window.location.replace(
      `//${WEB_HOST_AND_PORT}/web/login/auth?backUrl=${encodeURIComponent(
        window.location.toString()
      )}`
    );
    return true;
  }
});
