import { User } from "../types/commonTypes";

interface ReducerState {
  jwtToken: string;
  user: User;
  systemInfoMessage: string;
}

const SessionReducer = (state, action): ReducerState => {
  switch (action.type) {
    case "UpdateSession":
      return {
        ...state,
        jwtToken: action.jwtToken,
        user: action.user,
        systemInfoMessage: action.systemInfoMessage
      };
    case "InvalidSession":
      return {
        ...state,
        jwtToken: "",
        user: action.user,
        systemInfoMessage: action.systemInfoMessage
      };
    case "DeleteSession":
      return {
        ...state,
        jwtToken: "",
        user: null,
        systemInfoMessage: ""
      };
    case "BackEndServerError":
      return {
        ...state,
        jwtToken: "",
        user: action.user,
        systemInfoMessage: action.systemInfoMessage
      };
    default:
      return state;
  }
};

export default SessionReducer;
