/**
 * Cache invocations of fn based on its arguments
 */

const allResults = [];

const memoize = fn => {
  const index = allResults.length;
  allResults.push({});
  return (...args) => {
    const results = allResults[index];
    const key = JSON.stringify(args);
    if (!(key in results)) {
      results[key] = fn(...args);
    }
    return results[key];
  };
};

memoize.reset = () => {
  for (let i = 0; i < allResults.length; i++) {
    allResults[i] = {};
  }
};

export default memoize;
