import React, { ReactElement, useState, useReducer } from "react";
import Button from "@amzn/awsui-components-react/polaris/button";
import { Container } from "@amzn/et-polaris-utils";
import InvoiceSubmissionApiClient from "./InvoiceSubmissionApiClient";
import { Flash, FormField } from "@amzn/awsui-components-react";
import InvoiceSubmissionComponentReducer from "./InvoiceSubmissionComponentReducer";
import * as FileSaver from "file-saver";
import { User } from "../types/commonTypes";

const InvoiceSubmissionComponent = (props): ReactElement => {
  const defaultSubmissionComponent = {
    fileName: "",
    isSubmitting: false,
    flashBarText: "",
    flashBarError: false,
    flashBarSuccess: false
  };

  const user: User = props.session.user as User;
  const CSV_HEADER =
    "Job Part ID,Project Name,Job Part File Name,Invoice Month,Vault Team Name,PO Number,Exemption Note,Vendor Name,Source Language,Target Language,Start Date,Due Date,Completion Date,Exempt,Word Count,Repetition Word Count,TM ICE Match Word Count,TM 100% Word Count,TM 95%-99% Word Count,TM 85%-94% Word Count,TM 75%-84% Word Count,TER 0% Word Count,TER 1%-5% Word Count,TER 6%-9% Word Count,TER 10%-19% Word Count,TER 20%-29% Word Count,TER 30%-39% Word Count,TER 40%-49% Word Count,TER >50% Word Count,HT Word Count,Job None Translatable Word Count,Total Cost,Repetition Word Count Cost,TM ICE Match Word Count Cost,TM 100% Word Count Cost,TM 95%-99% Word Count Cost,TM 85%-94% Word Count Cost,TM 75%-84% Word Count Cost,TER 0% Word Count Cost,TER 1%-5% Word Count Cost,TER 6%-9% Word Count Cost,TER 10%-19% Word Count Cost,TER 20%-29% Word Count Cost,TER 30%-39% Word Count Cost,TER 40%-49% Word Count Cost,TER >50% Word Count Cost,HT Word Count Cost,Job None Translatable Word Count Cost,ENG Hourly Cost,Linguist Hourly Cost,Flat Fee Cost,Other Fee Cost\n";
  const [state, dispatch] = useReducer(
    InvoiceSubmissionComponentReducer,
    defaultSubmissionComponent
  );
  const invoiceSubmissionApiClient: InvoiceSubmissionApiClient = new InvoiceSubmissionApiClient();
  const TEMPLATE_FILENAME: string = "TAP-invoice-template.csv";
  const TEMPLATE_BLOB: Blob = new Blob([CSV_HEADER], {
    type: "text/csv;charset=utf-8"
  });

  let uploadInput: HTMLInputElement | null;

  const handleFileChange = event => {
    dispatch({ type: "hideFlashbars" });
    dispatch({
      type: "setFile",
      file: event.target.files[0],
      fileName: event.target.files[0].name
    });
  };

  const handleButtonClick = () => {
    // TODO remove dependency on a test flag - https://sim.amazon.com/issues/ETI-3618
    // Could not figure out a way to test this without a test flag. We need a file in order to unlock the submit button
    if (
      uploadInput != null &&
      (props.testFlag == undefined || props.testFlag == false)
    ) {
      uploadInput.click();
    } else {
      dispatch({ type: "hideFlashbars" });
      const file = new File([new ArrayBuffer(1)], "file.csv");
      dispatch({ type: "setFile", file: file, fileName: "test.csv" });
    }
  };

  const handleSubmit = async () => {
    dispatch({ type: "hideFlashbars" });
    dispatch({ type: "setIsSubmitting", isSubmitting: true });

    try {
      await invoiceSubmissionApiClient.submitInvoice(state.file, user.email);
      dispatch({ type: "setFlashbarSuccess", flashBarSuccess: true });
    } catch (e) {
      console.log("Catch submission error:" + e);
      dispatch({
        type: "setFlashbarError",
        flashBarError: true,
        flashBarText:
          "Please make sure " +
          "that all headers from the template CSV are included in your CSV file and that it is properly formatted"
      });
    }
    dispatch({ type: "setIsSubmitting", isSubmitting: false });
  };

  return (
    <div id="InvoiceSubmissionContainer">
      <div
        id="invoice-job-error-flash"
        css={{
          paddingTop: "14px",
          paddingBottom: "14px"
        }}
      >
        <Flash
          id="successFlash"
          visible={state.flashBarSuccess}
          header="Success"
          type="success"
          dismissible={false}
        >
          Your file has successfully been updated and your dashboards will be
          updated within one business day.
        </Flash>
        <Flash
          id="errorFlash"
          visible={state.flashBarError}
          header="Error"
          type="error"
          dismissible={false}
        >
          {state.flashBarText}
        </Flash>
      </div>

      <Container
        title="Invoice Upload"
        description="Upload an Invoice file to TAP. The submitted invoices will be processed and your dashboards will be updated within 24 hours."
      >
        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-group">
            <FormField
              label={"View Workflows Pending Invoice Information"}
              description={
                "The headers must be exactly the same as this template when you upload your invoice"
              }
            >
              <Button
                icon="status-pending"
                href="https://us-east-1.quicksight.aws.amazon.com/sn/dashboards/c6a282e6-7e0f-4951-8699-36a7bddf09fa"
              >
                Pending Invoice Dashboard
              </Button>
            </FormField>
          </div>
        </div>

        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-group">
            <FormField
              label={"Download blank .csv template"}
              description={
                "Blank invoice submission template for external invoices. The headers must be exactly the same as this template when you upload your invoice"
              }
            >
              <Button
                icon="download"
                onClick={() => {
                  FileSaver.saveAs(TEMPLATE_BLOB, TEMPLATE_FILENAME);
                }}
              >
                Download .csv template
              </Button>
            </FormField>
          </div>
        </div>

        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-group">
            <FormField
              label={"Upload .csv file"}
              id={"uploadCSVFormField"}
              hintText={state.fileName}
            >
              <input
                ref={input => {
                  uploadInput = input;
                }}
                type="file"
                id="invoiceFileInput"
                hidden={true}
                name="invoiceFile"
                accept=".csv"
                onChange={handleFileChange}
              />
              <Button
                wrapText={false}
                icon="upload"
                id="uploadInvoiceFileButton"
                onClick={() => handleButtonClick()}
              >
                Choose .csv file
              </Button>
            </FormField>
          </div>
        </div>

        <div className="awsui-util-action-stripe">
          <div className="awsui-util-action-stripe-title" />
          <div className="awsui-util-action-stripe-group">
            <Button
              text="Submit"
              id="submitButton"
              variant="primary"
              disabled={state.file == null || state.isSubmitting}
              onClick={() => handleSubmit()}
            />
          </div>
        </div>
      </Container>

      <div>
        <Container title="Invoice Upload Instructions">
          <div>
            <Container title="Important Reminders">
              <div>
                <h2>
                  DO:
                  <span style={{ fontSize: 16 }}>
                    {" "}
                    Always save CSV as a <b>UTF-8 CSV</b>.
                  </span>
                </h2>
                <h2>
                  DO:
                  <span style={{ fontSize: 16 }}>
                    {" "}
                    Use the format <b>MM/YYYY</b> for invoice month and select
                    Text for the cell format.
                  </span>
                </h2>
                <h2>
                  DO:
                  <span style={{ fontSize: 16 }}>
                    {" "}
                    Fill in all required columns illustrated in step 2 of the{" "}
                    <b>Adding data to Invoice CSV</b> section.
                  </span>
                </h2>
                <h2>
                  DO NOT:
                  <span style={{ fontSize: 16 }}>
                    {" "}
                    Remove any column headers.
                  </span>
                </h2>
                <h2>
                  DO NOT:
                  <span style={{ fontSize: 16 }}>
                    {" "}
                    Type <b>TRUE</b> into <b>EXEMPT</b> column for job parts
                    requiring invoice. (Exception: Split jobs. See Split job
                    section below.)
                  </span>
                </h2>
                <h2>
                  DO NOT:
                  <span style={{ fontSize: 16 }}>
                    {" "}
                    Type <b>EXTERNAL</b> into <b>Job Part</b> ID column for job
                    parts with a <b>Job Part ID</b> from ATMS. (Exception: Split
                    jobs. See Split job section below.)
                  </span>
                </h2>
                <h2>
                  DO NOT:
                  <span style={{ fontSize: 16 }}>
                    {" "}
                    Alter ATMS Job Part IDs or External Job Part IDs when
                    submitting an update.
                  </span>
                </h2>
              </div>
            </Container>
          </div>

          <div>
            <Container title="Downloading Invoice CSV">
              <div>
                <h4>Process Steps</h4>
                <ol>
                  <li>
                    Open the <b>Invoice Download Dashboard</b> on Quicksight.
                  </li>
                  <li>
                    Select the tab for the desired data (Ex. Pending Invoice).
                  </li>
                  <li>
                    Click ··· and select <b>Export to CSV</b>.
                  </li>
                  <li>Save CSV to computer.</li>
                </ol>
              </div>
            </Container>
          </div>

          <div>
            <Container title="Adding data to Invoice CSV">
              <div>
                <h4>Process Steps</h4>
                <ol>
                  <li>Open downloaded CSV</li>
                  <li>
                    Fill in all possible fields for each job part line. The
                    following fields are required for successful submission:
                    <ul>
                      <li>
                        <b>ATMS Job Parts: </b>Job Part ID, Invoice Month, and
                        Total Cost.{" "}
                      </li>
                      <li>
                        <b>Non-ATMS Job Parts: </b>Job Part ID (Initial
                        submission should be just the text <b>EXTERNAL</b> for
                        job part ID), Invoice Month, Word Count, Vendor Name, PO
                        Number, Source Language, Target Language, Start Date,
                        Due Date, Completion Date, and Total Cost.
                      </li>
                    </ul>
                  </li>
                  <li>Save CSV to computer.</li>
                </ol>
              </div>
            </Container>
          </div>

          <div>
            <Container
              title="Marking EXTERNAL Job Parts"
              description={"For completed Job Parts outside of ATMS."}
            >
              <div>
                <h4>Process Steps</h4>
                <ol>
                  <li>
                    Type <b>EXTERNAL</b> into the <b>Job Part ID</b> column.
                  </li>
                </ol>
              </div>
            </Container>
          </div>

          <div>
            <Container
              title="Marking EXEMPT Job Parts"
              description={
                "For Job Parts that will not be invoiced and do not require spend or when a duplicate submission occurred."
              }
            >
              <div>
                <h4>Process Steps</h4>
                <ol>
                  <li>
                    Type <b>TRUE</b> into the <b>EXEMPT</b> column.
                  </li>
                </ol>
              </div>
            </Container>
          </div>

          <div>
            <Container title="Uploading CSV">
              <div>
                <h4>Process Steps</h4>
                <ol>
                  <li>
                    Navigate to{" "}
                    <a href="https://atms.a2z.com/invoices/submit">this</a> page
                  </li>
                  <li>
                    Click <b>Choose CSV File</b>.
                  </li>
                  <li>Select CSV.</li>
                  <li>
                    Click Submit.
                    <ul>
                      <li>
                        The page will inform user whether the submission was a
                        success (green) or a failure (red).
                      </li>
                      <li>
                        Data will show on completed tab on the{" "}
                        <b>Invoice Download Dashboard</b> within one business
                        day.
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            </Container>
          </div>
        </Container>
      </div>

      <div>
        <Container title="Re-uploading Invoice Data">
          <div>
            <Container title="Correcting Vault Team Errors">
              <div>
                <h4>Process Steps</h4>
                <ol>
                  <li>
                    Open the <b>Invoice Download Dashboard</b> on Quicksight.
                  </li>
                  <li>
                    Select the <b>Errored</b> tab.
                  </li>
                  <li>
                    Click ··· and select <b>Export to CSV.</b>
                  </li>
                  <li>Save CSV to computer.</li>
                  <li>Open CSV and correct Vault Team names</li>
                  <li>Save CSV.</li>
                  <li>
                    Follow steps under the <b>Uploading CSV</b> section.
                  </li>
                </ol>
              </div>
            </Container>
          </div>

          <div>
            <Container title="Correcting CSV Errors">
              <div>
                <h4>Process Steps</h4>
                <ol>
                  <li>Open email with list of errors from submission.</li>
                  <li>Open CSV and correct identified errors from email.</li>
                  <li>Save CSV.</li>
                  <li>
                    Follow steps under the <b>Uploading CSV</b> section.
                  </li>
                </ol>
              </div>
            </Container>
          </div>

          <div>
            <Container title="Adding/Correcting Spend for Previously Submitted Data">
              <div>
                <h4>Process Steps</h4>
                <ol>
                  <li>
                    Open the <b>Invoice Download Dashboard</b> on Quicksight.
                  </li>
                  <li>
                    Select the <b>Completed</b> tab.
                  </li>
                  <li>
                    Click ··· and select <b>Export to CSV</b>.
                  </li>
                  <li>Save CSV to computer.</li>
                  <li>
                    Open CSV and update cost columns.
                    <ul>
                      <li>
                        <b>DO NOT</b> edit other data for the job part with the
                        corrected spend.
                      </li>
                      <li>
                        External job parts must have the external job part ID
                        created after the initial submission.
                      </li>
                      <li>
                        When additional spend requires invoice for a submitted
                        job part <b>within the same month</b>, only the cost
                        columns with the new total.
                      </li>
                      <li>
                        When additional spend requires invoice for a submitted
                        job part{" "}
                        <b>in a separate month from the original work</b>,
                        update the invoice month and have the cost reflect only
                        the additional amount.
                      </li>
                    </ul>
                  </li>
                  <li>
                    Save CSV.
                    <ul>
                      <li>
                        Follow steps under the <b>Uploading CSV</b> section.
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            </Container>
          </div>
        </Container>
      </div>

      <div>
        <Container title="Troubleshooting">
          <div>
            <Container
              title="Split Job Issue"
              description={
                "ATMS split job feature should not be used as it is unsupported. In the event this feature is used, the following steps must be followed to ensure invoice data is properly processed."
              }
            >
              <div>
                <h4>Process Steps</h4>
                <ol>
                  <li>
                    Follow steps under the <b>Downloading Invoice CSV</b>{" "}
                    section.
                  </li>
                  <li>
                    Type <b>TRUE</b> into the <b>EXEMPT</b> column for any split
                    job part.
                  </li>
                  <li>
                    Type <b>Split Job Part</b> into the <b>Exemption Note</b>{" "}
                    column.
                  </li>
                  <li>
                    Type <b>EXTERNAL</b> into the Job Part ID in a new row.
                  </li>
                  <li>Fill in required fields for the EXTERNAL Job Part</li>
                  <ul>
                    <li>
                      Ensure wordcount totals reflect the full job part before
                      being split.
                    </li>
                  </ul>
                  <li>Save CSV.</li>
                  <li>
                    Follow steps under the <b>Uploading CSV</b> section.
                  </li>
                </ol>
              </div>
            </Container>
          </div>
        </Container>
      </div>

      <div>
        <Container title="Contact Us">
          <div>
            To contact the Invoices team for any issues or feature requests
            please file a ticket using ATMS Tickets and select{" "}
            <b>Reporting and Analytics</b> as the problem type.
          </div>
        </Container>
      </div>
    </div>
  );
};

export default InvoiceSubmissionComponent;
