import React, { ReactElement } from "react";
import styled from "@emotion/styled";
import { cx, css } from "emotion";
import Icon from "../../shared/Icon";

const withIconAndEllipsis = Component => ({ children, icon, ...props }) => (
  <Component
    {...props}
    css={{
      display: "inline-flex",
      alignItems: "center",
      maxWidth: "100%",
      "& svg": {
        height: "16px",
        width: "16px",
        marginRight: "0.5em",
        flexShrink: 0
      },
      "& .awsui-icon": {
        marginRight: "0.5em"
      }
    }}
  >
    {icon && <Icon name={icon} />}
    <span
      css={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "100%"
      }}
    >
      {children}
    </span>
  </Component>
);

const NavBox = styled.section({
  lineHeight: "2rem",
  minHeight: "100%",
  ".awsui & .awsui-expandable-section-header-icon": {
    margin: "0 0.5em 0 0"
  },
  ".awsui & .awsui-expandable-section-header": {
    color: "#879596"
  }
});

const NavHeaderDiv = styled.div({
  "& h2, & h2 a": {
    fontWeight: 700,
    fontSize: "18px",
    color: "#16191f !important"
  },
  "& h2": {
    padding: "20px 40px 20px 30px",
    margin: "0"
  }
});

const NavHeader = ({ title }) => {
  return (
    <NavHeaderDiv>
      <h2>
        <div>{title}</div>
      </h2>
    </NavHeaderDiv>
  );
};

const NavSection = styled.div({
  borderTop: "1px solid #d5dbdb",
  padding: "20px 30px",
  ".awsui & li": {
    margin: "10px 0"
  }
});

const NavGroup = styled.ul({
  listStyle: "none",
  padding: "0 !important",
  margin: "0 !important"
});

const NavItem = styled.li({
  padding: "0 !important",
  margin: "10px 0 !important"
});

/**
 react-router NavLink with:
 * icon/ellipses handling
 * additional active path support
 * other style tweaks
 */

const PrimaryNavLink = withIconAndEllipsis(props => (
  <a
    className={css({
      color: "#16191f !important",
      "a&": {
        fontWeight: 400,
        color: "#879596",
        textDecoration: "none"
      },
      "&:hover": {
        color: "#ec7211 !important"
      },
      "&:hover, &:active, &:focus": {
        textDecoration: "none !important"
      }
    })}
    {...props}
  />
));

const NavigationModal = (props): ReactElement => {
  return (
    <NavBox>
      <NavHeader title="ATMS Invoices" />
      <NavSection>
        <NavGroup>
          <NavItem>
            <PrimaryNavLink href="/web" icon={Icon.for("project")}>
              Projects
            </PrimaryNavLink>
          </NavItem>
          <NavItem>
            <PrimaryNavLink href="/web/tickets/list" icon="edit">
              ATMS Tickets
            </PrimaryNavLink>
          </NavItem>
        </NavGroup>
      </NavSection>
    </NavBox>
  );
};

export default NavigationModal;
