interface ModalState {
  file?: FileList;
  fileName: string;
  isSubmitting: boolean;
  flashBarText: string;
  flashBarError: boolean;
  flashBarSuccess: boolean;
}

const InvoiceSubmissionComponentReducer = (state, action): ModalState => {
  switch (action.type) {
    case "setFile":
      return {
        ...state,
        file: action.file,
        fileName: action.fileName
      };
    case "setIsSubmitting":
      return {
        ...state,
        isSubmitting: action.isSubmitting
      };
    case "hideFlashbars":
      return {
        ...state,
        flashBarError: false,
        flashBarSuccess: false
      };
    case "setFlashbarError":
      return {
        ...state,
        flashBarError: action.flashBarError,
        flashBarText: action.flashBarText
      };
    case "setFlashbarSuccess":
      return {
        ...state,
        flashBarSuccess: action.flashBarSuccess
      };
    default:
      return state;
  }
};

export default InvoiceSubmissionComponentReducer;
