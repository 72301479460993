import { User } from "../types/commonTypes";
import {
  INVALID_PAYLOAD,
  UNAUTHORIZED,
  AUTHENTICATED,
  BACKEND_ERROR
} from "../types/constants";
import cookieName from "../../shared/session/sessionCookieName";
import * as Cookies from "js-cookie";

const GetUserInfo = async (): Promise<User> => {
  let userObject: User = {
    userId: -1,
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    organizationId: -1,
    role: "",
    authenticationStatus: "",
    availableProfiles: [],
    csrfToken: "",
    systemInfoMessage: ""
  };

  const url = "/web/api/v3/auth/whoAmI?extended=true&rnd=";

  await fetch(url + Math.random(), {
    credentials: "same-origin",
    headers: {
      Authorization: `Bearer ${Cookies.get(cookieName)}`
    }
  })
    .then(response => {
      console.log(response);
      if (response.status == 200) return response.json();
      else if (response.status == 401) {
        userObject.authenticationStatus = UNAUTHORIZED;
        throw new Error(UNAUTHORIZED);
      } else {
        userObject.authenticationStatus = BACKEND_ERROR;
        throw new Error(response.statusText);
      }
    })
    .then(data => {
      const userData = data["user"];
      const orgData = data["organization"];

      if (
        userData !== undefined &&
        orgData !== undefined &&
        userData["id"] !== undefined &&
        userData["userName"] !== undefined &&
        orgData["id"] !== undefined &&
        userData["role"] !== undefined
      ) {
        userObject.userId = data["user"]["id"];
        userObject.userName = data["user"]["userName"];
        userObject.firstName = data["user"]["firstName"];
        userObject.lastName = data["user"]["lastName"];
        userObject.email = data["user"]["email"];
        userObject.organizationId = data["organization"]["id"];
        userObject.role = data["user"]["role"];
        userObject.authenticationStatus = AUTHENTICATED;
        userObject.availableProfiles = data["availableProfiles"];
        userObject.csrfToken = data["csrfToken"];
        userObject.systemInfoMessage = data["systemInfoMessage"];
      } else {
        // In a very rare situation.
        userObject.authenticationStatus = INVALID_PAYLOAD;
        throw new Error(INVALID_PAYLOAD);
      }
    })
    .catch(error => {
      console.log("Error => " + error);
    });

  return userObject;
};

export default GetUserInfo;
